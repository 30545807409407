/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Typography,
  Space,
  Button,
  Empty,
  message,
  Modal,
  Form,
  Input,
  Dropdown,
  Row,
  Col,
  Alert,
  Tooltip,
} from "antd";
import {
  TeamOutlined,
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  MoreOutlined,
  DownOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import TeamUsers from "./TeamUsers";

const { Title, Text } = Typography;

const TeamManagement = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [teams, setTeams] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);
  const [isEditTeamModalVisible, setIsEditTeamModalVisible] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editError, setEditError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [selectionType] = useState("checkbox");

  const [form] = Form.useForm();

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const currentUserData = await fetchCurrentUser();
      if (!currentUserData) {
        return;
      }
      const userIdFromToken = currentUserData.id || currentUserData.userId;
      setUserId(userIdFromToken);
      fetchTeams(userIdFromToken);
    };

    initialize();
  }, []);

  const fetchTeams = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/teams/get-teams-by-user-id`,
        { userId },
        {
          withCredentials: true,
        }
      );
      setTeams(response.data.teams);
    } catch (error) {
      console.error("Error fetching teams:", error);
      message.error("An error occurred while fetching teams.");
    }
  };

  const showCreateTeamModal = () => {
    setIsCreateTeamModalVisible(true);
  };

  const handleCreateTeamCancel = () => {
    setIsCreateTeamModalVisible(false);
    form.resetFields();
  };

  const handleCreateTeam = async (values) => {
    setIsCreate(true);
    if (!userId) return;
    try {
      await axios.post(`${API_URL}/api/teams/create-team`, values, {
        withCredentials: true,
      });
      fetchTeams(userId);
      setIsCreateTeamModalVisible(false);
      form.resetFields();
      message.success("Team created successfully.");
    } catch (error) {
      console.error("Error occurred while creating team:", error);
      message.error("An error occurred while creating team.");
    } finally {
      setIsCreate(false);
    }
  };

  const showEditTeamModal = (team) => {
    setEditingTeam(team);
    form.setFieldsValue({ name: team.name });
    setIsEditTeamModalVisible(true);
  };

  const handleEditTeamCancel = () => {
    setIsEditTeamModalVisible(false);
    setEditingTeam(null);
    form.resetFields();
  };

  const handleEditTeam = async (values) => {
    if (!editingTeam) return;
    setIsEdit(true);
    setEditError(null); // Önceki hatayı temizle
    try {
      await axios.put(
        `${API_URL}/api/teams/update-team`,
        { ...values, teamId: editingTeam.id },
        {
          withCredentials: true,
        }
      );
      fetchTeams(userId);
      setIsEditTeamModalVisible(false);
      setEditingTeam(null);
      form.resetFields();
      message.success("Team updated successfully.");
    } catch (error) {
      console.error("Error occurred while updating team:", error);

      if (error.response && error.response.status === 403) {
        setEditError("Only the team creator can edit the team.");
      } else {
        message.error("An error occurred. Please try again.");
      }
    } finally {
      setIsEdit(false);
    }
  };

  // Function for single team deletion with confirmation modal

  const showDeleteTeamModal = (team) => {
    Modal.confirm({
      title: "Are you sure you want to delete this team?",
      content: (
        <div>
          <Text>
            The team <strong>{team.name}</strong> will be deleted. This action
            cannot be undone.
          </Text>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        setDeleteError(null); // Önceki hatayı temizle
        try {
          await axios.post(
            `${API_URL}/api/teams/delete-team`,
            { teamId: team.id },
            {
              withCredentials: true,
            }
          );
          fetchTeams(userId);
          message.success("Team deleted successfully.");

          if (selectedTeam && selectedTeam.id === team.id) {
            setSelectedTeam(null);
          }
        } catch (error) {
          console.error("Error occurred while deleting team:", error);

          if (error.response && error.response.status === 403) {
            setDeleteError("Only the team creator can delete the team.");
          } else {
            message.error("An error occurred while deleting the team.");
          }
        }
      },
    });
  };

  const handleBulkDelete = () => {
    if (!selectedRowKeys.length) {
      message.warning("Please select at least one team to delete.");
      return;
    }

    const selectedTeams = teams.filter((team) =>
      selectedRowKeys.includes(team.id)
    );
    const selectedNames = selectedTeams.map((team) => team.name).join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected teams?",
      content: (
        <span>
          The following teams will be deleted:{" "}
          <Text strong>{selectedNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Promise.all(
            selectedRowKeys.map((teamId) =>
              axios.post(
                `${API_URL}/api/teams/delete-team`,
                { teamId },
                {
                  withCredentials: true,
                }
              )
            )
          );
          message.success("Selected teams have been deleted successfully.");
          setSelectedRowKeys([]);
          fetchTeams(userId);
        } catch (error) {
          console.error("Error occurred during bulk deletion:", error);
          message.error("Bulk deletion failed.");
        }
      },
    });
  };

  const handleToggleTeamUsers = (team) => {
    setSelectedTeam((prev) => (prev && prev.id === team.id ? null : team));
  };

  const showInviteUserModal = (team) => {
    setSelectedTeam(team); // Seçili takımı belirle
    setIsInviteModalVisible(true); // Modalı aç
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          <TeamOutlined />
          <Button type="link" onClick={() => handleToggleTeamUsers(record)}>
            {text}
          </Button>
        </Space>
      ),
    },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "edit",
                  icon: <EditOutlined />,
                  label: "Edit",
                  onClick: () => showEditTeamModal(record),
                },
                {
                  key: "add-user",
                  icon: <UserAddOutlined />,
                  label: "Add User",
                  onClick: () => showInviteUserModal(record),
                },
                {
                  key: "delete",
                  icon: <DeleteOutlined style={{ color: "red" }} />,
                  label: <Space style={{ color: "red" }}>Delete</Space>,
                  onClick: () => showDeleteTeamModal(record),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined
                style={{
                  fontSize: "20px",
                  color: selectedRowKeys.includes(record.id)
                    ? "#fff"
                    : "inherit",
                }}
              />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const text = (
    <span>
      In Team Management, you can create teams with the users you’ve added and share secrets, folders,
       and secure notes collectively, making collaboration easier and more secure.
    </span>
  );

  return (
    <div>
      <Title level={2}>
        Team Management{" "}
        <Tooltip placement="top" title={text}>
          <QuestionCircleOutlined style={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col xs={24} sm="auto">
          <Button
            type="primary"
            onClick={showCreateTeamModal}
            disabled={!userId}
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            Create Team
          </Button>

          <Dropdown
            disabled={selectedRowKeys.length === 0}
            menu={{
              items: [
                {
                  key: "bulk-delete",
                  icon: <DeleteOutlined style={{ color: "red" }} />,
                  label: <Space style={{ color: "red" }}>Delete</Space>,
                  onClick: handleBulkDelete,
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Actions <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {deleteError && (
        <Alert
          message="Warning"
          description={deleteError}
          type="warning"
          showIcon
          closable
          style={{ marginTop: 16 }}
        />
      )}
      {teams.length === 0 ? (
        <Empty description="No Team" />
      ) : (
        <Table
          dataSource={teams}
          columns={columns}
          pagination={false}
          rowKey="id"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ x: "max-content" }}
        />
      )}
      {selectedTeam && (
        <TeamUsers
          team={selectedTeam}
          fetchTeams={fetchTeams}
          userId={userId}
          isInviteModalVisible={isInviteModalVisible}
          setIsInviteModalVisible={setIsInviteModalVisible}
        />
      )}
      <Modal
        title="Create Team"
        open={isCreateTeamModalVisible}
        onCancel={handleCreateTeamCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateTeam}>
          <Form.Item
            name="name"
            label="Team Name"
            rules={[
              {
                required: true,
                message: "Please enter team name!",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isCreate}
              loading={isCreate}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Team"
        open={isEditTeamModalVisible}
        onCancel={handleEditTeamCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleEditTeam}>
          {editError && (
            <Alert
              description={editError}
              type="warning"
              closable
              style={{ marginBottom: 16 }}
            />
          )}
          <Form.Item
            name="name"
            label="Team Name"
            rules={[
              {
                required: true,
                message: "Please enter team name!",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isEdit}
              loading={isEdit}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeamManagement;
