/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
  SafetyOutlined,
  DashboardOutlined,
  UserOutlined,
  ShareAltOutlined,
  FileProtectOutlined,
  EditOutlined,
  ControlOutlined,
  GlobalOutlined,
  LogoutOutlined,
  SettingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  theme,
  Dropdown,
  Switch,
  ConfigProvider,
  Space,
  Spin,
  Avatar,
  FloatButton,
} from "antd";
import {
  darkTokens,
  lightTokens,
  componentOverridesDark,
  componentOverridesLight,
} from "../../theme/colors";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import { Footer } from "antd/es/layout/layout";

const { Header, Content, Sider } = Layout;

const LayoutMain = ({ children, onLogout }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });
  const [collapsed, setCollapsed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const isDashboard =
    pathSnippets.length === 0 || pathSnippets[0] === "dashboard";

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      if (width < 970) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
    }
  };

  const fetchBreadcrumbItems = async () => {
    try {
      const items = await Promise.all(
        pathSnippets.map(async (snippet, index) => {
          const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
          let displayName = snippet;
          let breadcrumbItem;

          if (
            snippet.match(/^[0-9a-fA-F]{24}$/) ||
            snippet.match(/^[0-9a-fA-F-]{36}$/)
          ) {
            return null;
          }

          if (pathSnippets[0] === "apps" && index === 1) {
            const response = await axios.get(`${API_URL}/api/apps/${snippet}`, {
              withCredentials: true,
            });
            displayName = response.data.name;
            breadcrumbItem = (
              <Breadcrumb.Item key={url}>
                <Link to={url}>{displayName}</Link>
              </Breadcrumb.Item>
            );
          } else if (pathSnippets[0] === "folders" && index === 2) {
            const response = await axios.get(
              `${API_URL}/api/folders/app/${snippet}`,
              {
                withCredentials: true,
              }
            );
            displayName = response.data.name;
            breadcrumbItem = (
              <Breadcrumb.Item key={url}>
                <Link to={url}>{displayName}</Link>
              </Breadcrumb.Item>
            );
          } else if (pathSnippets[0] === "settings") {
            if (snippet === "emergency-access") {
              displayName = "Emergency Access";
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else if (snippet === "password") {
              displayName = "Password Change";
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else if (snippet === "Logs") {
              displayName = "Logs";
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else if (snippet === "payment") {
              displayName = "Payment";
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else {
              displayName = "Settings";
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>{displayName}</Breadcrumb.Item>
              );
            }
          } else {
            displayName = snippet
              .replace(/-/g, " ")
              .split(" ")
              .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
              .join(" ");
            breadcrumbItem = (
              <Breadcrumb.Item key={url}>
                <Link to={url}>{displayName}</Link>
              </Breadcrumb.Item>
            );
          }
          return breadcrumbItem;
        })
      );

      setBreadcrumbItems([
        !isDashboard && (
          <Breadcrumb.Item key="home">
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
        ),
        ...items,
      ]);
    } catch (error) {
      console.error("Error fetching breadcrumb items:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchBreadcrumbItems();
    setLoading(false);
  }, [location.pathname]);

  const logoutUser = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/logout`,
        {},
        { withCredentials: true }
      );
      if (response.status === 200) {
        onLogout();
        navigate("/login");
      }
    } catch (error) {
      console.error(
        "An error occurred during the logout process:",
        error.response ? error.response.data.error : error.message
      );
    }
  };

  const handleThemeChange = (checked) => {
    setIsDarkMode(checked);
    localStorage.setItem("isDarkMode", JSON.stringify(checked));
  };

  const sideMenu = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: "apps",
      icon: <SafetyOutlined />,
      label: <Link to="/apps">Apps</Link>,
    },
    {
      key: "config-manager",
      icon: <ControlOutlined />,
      label: <Link to="/config-manager">Config Manager</Link>,
    },
    {
      key: "shared items",
      icon: <ShareAltOutlined />,
      label: <Link to="/shared-items">Shared items</Link>,
    },
    {
      key: "secure-notes",
      icon: <FileProtectOutlined />,
      label: <Link to="/secure-notes">Secure Notes</Link>,
    },
    {
      key: "builder",
      icon: <EditOutlined />,
      label: <Link to="/builder">Builder</Link>,
    },
    {
      key: "user-management",
      icon: <UserOutlined />,
      label: <Link to="/user-management">User Management</Link>,
    },
  ];

  const user = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "2",
      icon: <SettingOutlined />,
      label: "Settings",
      children: [
        {
          key: "2-1",
          label: <Link to="/settings/password">Password</Link>,
        },
      ],
    },
    {
      key: "3",
      icon: <LogoutOutlined />,
      onClick: logoutUser,
      label: "Log out",
    },
  ];

  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).slice(-2);
    }
    return color;
  }

  const UserAvatar = ({ name }) => {
    const bgColor = useMemo(() => {
      if (!name) return "#ccc";
      return stringToColor(name);
    }, [name]);

    const initial = name ? name.charAt(0).toUpperCase() : "?";

    return (
      <Avatar
        style={{
          backgroundColor: bgColor,
          verticalAlign: "middle",
          width: "20px",
          height: "20px",
        }}
      >
        {initial}
      </Avatar>
    );
  };

  const currentTokens = isDarkMode ? darkTokens : lightTokens;
  const currentComponentOverrides = isDarkMode
    ? componentOverridesDark
    : componentOverridesLight;

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: currentTokens,
        components: currentComponentOverrides,
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={210}
            collapsedWidth={80}
            className="sider"
            style={{
              height: "100%",
              transition: "all 0.4s ease",
            }}
          >
            <Header className="header" style={{ width: collapsed ? 80 : 210 }}>
              <a href="/dashboard" className="demo-logo">
                <img
                  src={collapsed ? "/logo.png" : "/logo.png"}
                  className="logo"
                  alt="Logo"
                  style={{
                    width: collapsed ? "60px" : "55px",
                    transition: "width 0.4s ease",
                  }}
                />
                {!collapsed && (
                  <span className="Demo-logo-span">SecureNook</span>
                )}
              </a>
            </Header>
            <Menu
              theme={isDarkMode ? "dark" : "light"}
              mode="inline"
              defaultOpenKeys={["side1"]}
              className="sider-header"
              items={sideMenu}
            />
            <Footer
              className="sider-footer"
              style={{
                paddingLeft: "20px",
                marginTop: collapsed ? "-180px" : "-130px",
                flexDirection: collapsed ? "column" : "row",
                alignItems: collapsed ? "flex-start" : "center",
                justifyContent: collapsed ? "flex-start" : "space-between",
              }}
              direction={collapsed ? "vertical" : "horizontal"}
            >
              <Switch
                checked={isDarkMode}
                onChange={handleThemeChange}
                checkedChildren="Dark"
                unCheckedChildren="Light"
                style={{
                  background: "#394867",
                  marginLeft: collapsed ? "-10px" : "0px",
                }}
              />
              <img
                src="/tr.png"
                className="logo"
                alt="Logo"
                style={{ width: "20px", marginBottom: "0px" }}
              />
            </Footer>
          </Sider>

          <Layout
            style={{
              marginLeft: collapsed ? 80 : 210,
            }}
          >
            <div className="breadcrumb-main">
              <Space>
                {!isDashboard && (
                  <Breadcrumb className="ant-breadcrumb">
                    {breadcrumbItems}
                  </Breadcrumb>
                )}
              </Space>
              <Space>
                <Dropdown
                  menu={{ items: user }}
                  placement="top"
                  trigger={"click"}
                >
                  <Button
                    type="text"
                    size="small"
                    icon={<UserAvatar name={userData ? userData.name : "?"} />}
                    className="Notification-btn"
                  >
                    {loading ? (
                      <Spin tip="Yükleniyor..." />
                    ) : (
                      userData?.name || "?"
                    )}
                  </Button>
                </Dropdown>
                <GlobalOutlined
                  style={{
                    width: "20px",
                    height: "20px",
                    color: isDarkMode ? "#fff" : "#14274E",
                  }}
                />
              </Space>
            </div>
            <Content
              style={{
                background: isDarkMode ? "#14274E" : "#fff",
                borderRadius: borderRadiusLG,
              }}
              className="content"
            >
              {React.cloneElement(children, { isDarkMode })}
              <FloatButton
                type="primary"
                icon={<MailOutlined />}
                tooltip={<>Support or Suggestion</>}
                style={{ insetInlineEnd: 24 }}
                onClick={() => {
                  const email = "securenook@gmail.com";
                  const subject = encodeURIComponent("Support or Suggestion");
                  window.open(`mailto:${email}?subject=${subject}`, "_blank");
                }}
              />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutMain;
