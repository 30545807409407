/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Input, Form, Button, message } from "antd";
import axios from "axios";

const { TextArea } = Input;

const SecureNoteNewModal = ({
  isModalVisible,
  setIsModalVisible,
  onNoteCreated,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [isNote, setIsNote] = useState(false);

  const handleCreateSecureNote = async () => {
    setIsNote(true);
    try {
      const values = await form.validateFields();

      const payload = {
        title: values.title,
        note: values.note,
      };

      await axios.post(`${API_URL}/api/secure-note/add-secure-note`, payload, {
        withCredentials: true,
      });
      form.resetFields();
      setIsModalVisible(false);
      if (onNoteCreated) {
        onNoteCreated();
      }
      message.success("Secure note created successfully");
    } catch (error) {
      console.error("Error creating secure note:", error?.response?.data);
      message.error("Failed to create secure note");
    } finally {
      setIsNote(false);
    }
  };

  return (
    <Modal
      title="New Note"
      open={isModalVisible}
      onCancel={() => {
        form.resetFields();
        setIsModalVisible(false);
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            setIsModalVisible(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleCreateSecureNote}
          disabled={isNote}
          loading={isNote}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter title!" }]}
        >
          <Input placeholder="Enter title" autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Note"
          name="note"
          rules={[{ required: true, message: "Please enter a note!" }]}
        >
          <TextArea rows={4} placeholder="Enter note" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SecureNoteNewModal;
