import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Typography, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import "./AuthForm.css";

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/api/auth/forgot-password`, {
        email: values.email,
      });

      if (response.status === 200) {
        message.success("A password reset email has been sent.");
        setEmailSent(true);
        setTimer(120);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        message.error("The e-mail address was not found in the system.");
      } else {
        message.error("An error has occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <Card className="forgot-password-card" bordered={false}>
        <div className="logo-container">
          <img src="/logo2.png" alt="Logo" className="logo" />
        </div>
        <div className="title-container">
          <Title level={3} className="main-title">
            Forgot Your Password?
          </Title>
        </div>

        <Text className="subtitle">
          Enter your email address to receive a password reset link.
        </Text>

        <Form
          name="forgot-password"
          layout="vertical"
          onFinish={onFinish}
          className="forgot-form"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address!",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Your email address"
              prefix={<MailOutlined />}
            />
          </Form.Item>

          <Form.Item>
            {timer > 0 ? (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: '18px',
                }}
                type="secondary"
              >
                Resend available in {timer}s
              </p>
            ) : (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loading}
                block
              >
                {emailSent ? "Send Again" : "Send Reset Link"}
              </Button>
            )}
          </Form.Item>

          <div className="back-to-login">
            <Link to="/login">Back to Login</Link>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
