import React, { useState, useEffect } from "react";
import { Modal, Select, Button, message } from "antd";
import axios from "axios";

const ShareModal = ({ visible, onCancel, items, userId }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  useEffect(() => {
    const fetchUsersAndTeams = async () => {
      try {
        const usersResponse = await axios.post(
          `${API_URL}/api/invite/get-invited-users`,
          { userId },
          {
            withCredentials: true,
          }
        );
        setUsers(usersResponse.data);

        const teamsResponse = await axios.post(
          `${API_URL}/api/teams/get-teams-by-user-id`,
          { userId },
          {
            withCredentials: true,
          }
        );
        setTeams(teamsResponse.data.teams);
      } catch (error) {
        console.error("Failed to load users and teams:", error);
        message.error("Could not load user/team data.");
      }
    };

    if (visible) {
      setSelectedUsers([]);
      setSelectedTeams([]);
      fetchUsersAndTeams();
    }
  }, [visible, userId, API_URL]);

  const handleShare = async () => {
    if (selectedUsers.length === 0 && selectedTeams.length === 0) {
      message.error("You must select at least one user or team!");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/share/share-items`,
        {
          items,
          userIds: selectedUsers,
          teamIds: selectedTeams,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        message.success("Sharing successful!");
        onCancel();
      } else {
        message.error(
          response.data.error || "An error occurred while sharing."
        );
      }
    } catch (error) {
      console.error("Sharing API error:", error);
      message.error(
        error.response?.data?.error || "An error occurred while sharing."
      );
    }
  };

  return (
    <Modal
      title="Share Items"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="share" type="primary" onClick={handleShare}>
          Share
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 16 }}>
        <h4>Select User(s)</h4>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select user(s)"
          onChange={(value) => setSelectedUsers(value)}
          value={selectedUsers}
          options={users.map((user) => ({
            key: user.id,
            value: user.id,
            label: user.name,
          }))}
        />
      </div>
      <div>
        <h4>Select Team(s)</h4>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select team(s)"
          onChange={(value) => setSelectedTeams(value)}
          value={selectedTeams}
          options={teams.map((team) => ({
            key: team.id,
            value: team.id,
            label: team.name,
          }))}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
