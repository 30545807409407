/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Avatar,
  Button,
  Modal,
  Select,
  message,
  Typography,
  Table,
  Alert,
  Row,
  Col,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const TeamUsers = ({
  team,
  userId,
  isInviteModalVisible,
  setIsInviteModalVisible,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [removeError, setRemoveError] = useState(null);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  useEffect(() => {
    if (team && team.id) {
      fetchTeamUsers(team.id);
    }
  }, [team]);

  useEffect(() => {
    const initialize = async () => {
      const currentUserData = await fetchCurrentUser();
      if (!currentUserData) {
        return;
      }
      const userIdFromToken = currentUserData.id || currentUserData.userId;
      fetchInviteUsers(userIdFromToken);
    };

    initialize();
  }, []);

  const fetchTeamUsers = async (teamId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/teams/get-team-members`,
        { teamId },
        {
          withCredentials: true,
        }
      );

      if (response.data && Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        message.error("User data is not in the expected format.");
      }
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const fetchInviteUsers = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/invite/get-invited-users`,
        { userId },
        {
          withCredentials: true,
        }
      );
      const invitedUsers = response.data.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
      }));
      setInviteUsers(invitedUsers);
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const handleAddUser = async () => {
    setIsAdd(true);
    try {
      await axios.post(
        `${API_URL}/api/teams/add-user-to-team`,
        {
          teamId: team.id,
          userIds: selectedUserIds,
          role: "user",
        },
        {
          withCredentials: true,
        }
      );
      fetchTeamUsers(team.id);
      setIsInviteModalVisible(false);
      setSelectedUserIds([]);
      message.success("Kullanıcı başarıyla eklendi.");
    } catch (error) {
      console.error("An error occurred while adding user:", error);
      message.error("An error occurred while adding user.");
    } finally {
      setIsAdd(false);
    }
  };

  const handleRemoveUser = async (userId, teamId) => {
    if (users.length === 1) {
      message.warning("The last user in the team cannot be removed.");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/api/teams/remove-user-from-team`,
        { teamId, userId },
        { withCredentials: true }
      );

      message.success("User successfully removed.");

      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
    } catch (error) {
      console.error("An error occurred while removing user:", error);

      if (error.response && error.response.status === 403) {
        setRemoveError("Only the team creator can remove users.");
      } else {
        message.error("An error occurred while removing user.");
      }
    }
  };

  const showInviteModal = async () => {
    const currentUserData = await fetchCurrentUser();
    if (!currentUserData) {
      return;
    }
    const userIdFromToken = currentUserData.id || currentUserData.userId;
    await fetchInviteUsers(userIdFromToken);
    setIsInviteModalVisible(true);
  };

  const handleInviteModalCancel = () => {
    setSelectedUserIds([]);
    setIsInviteModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, user) => (
        <span>
          <Avatar style={{ marginRight: 8 }}>
            {user.name ? user.name[0] : "?"}
          </Avatar>
          {user.name || "Unknown"}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "Email not available",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, user) =>
        users.length > 1 &&
        user.id !== userId && (
          <Button
            type="danger"
            onClick={() => handleRemoveUser(user.id, team.id)}
            style={{ width: "100%", maxWidth: 200, color: "red" }}
          >
            <DeleteOutlined />
          </Button>
        ),
    },
  ];

  return (
    <>
      {removeError && (
        <Alert
          message="Warning"
          description={removeError}
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 8 }}
        />
      )}
      <Card
        title={
          <Row
            gutter={[4, 4]}
            justify="space-between"
            align="middle"
            wrap
            style={{ marginBottom: 10 }}
          >
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Title
                level={5}
                style={{ margin: 0, lineHeight: "32px", width: "100%" }}
              >
                {`${team.name} Users`}
              </Title>
            </Col>

            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="button-col"
            >
              <Button
                type="primary"
                onClick={showInviteModal}
                style={{ maxWidth: 150 }}
                icon={<PlusOutlined />}
              >
                Add User
              </Button>
            </Col>
          </Row>
        }
        style={{ marginTop: "16px" }}
      >
        {users.length === 0 ? (
          <p>There are no users in this team.</p>
        ) : (
          <Table
            dataSource={users}
            rowKey={(user) => user.id}
            pagination={{ pageSize: 5 }}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
        )}
      </Card>

      <Modal
        title="Invite User"
        open={isInviteModalVisible}
        onCancel={handleInviteModalCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleInviteModalCancel}
            style={{ width: "100%", maxWidth: 200 }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddUser}
            style={{ width: "100%", maxWidth: 200 }}
            loading={isAdd}
            disabled={isAdd}
          >
            Add
          </Button>,
        ]}
      >
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Invited Users"
          onChange={setSelectedUserIds}
          value={selectedUserIds}
          options={inviteUsers.map((user) => ({
            key: user.id,
            value: user.id,
            label: (
              <>
                {user.name} - {user.email}
              </>
            ),
          }))}
        />
      </Modal>
      <style jsx>{`
        @media (min-width: 768px) {
          .button-col {
            justify-content: flex-end; /* Geniş ekranda sağa hizalama */
          }
        }
      `}</style>
    </>
  );
};

export default TeamUsers;
