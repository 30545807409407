/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Space, Table, Typography } from "antd";
import axios from "axios";
import {
  AppstoreOutlined,
  KeyOutlined,
  UserOutlined,
  ControlOutlined,
  FileProtectOutlined,
  TeamOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import './index.css';

const { Title } = Typography;

const Dashboard = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [summaryCards, setSummaryCards] = useState([
    {
      key: "apps",
      title: (
        <Space>
          <AppstoreOutlined style={{ fontSize: "25px" }} />
          <Link className="link" to="/apps">Apps</Link>
        </Space>
      ),
      count: 0,
    },
    {
      key: "secrets",
      title: (
        <Space>
          <KeyOutlined style={{ fontSize: "25px" }} />
          Secrets
        </Space>
      ),
      count: 0,
    },
    {
      key: "teams",
      title: (
        <Space>
          <TeamOutlined style={{ fontSize: "25px" }} />
          <Link className="link" to="/user-management">Teams</Link>
        </Space>
      ),
      count: 0,
    },
    {
      key: "envFiles",
      title: (
        <Space>
          <ControlOutlined style={{ fontSize: "25px" }} />
          <Link className="link" to="/config-manager">Config</Link>
        </Space>
      ),
      count: 0,
    },
    {
      key: "shared",
      title: (
        <Space>
          <ShareAltOutlined style={{ fontSize: "25px" }} />
          <Link className="link" to="/shared-items">Shared</Link>
        </Space>
      ),
      count: 0,
    },
    {
      key: "note",
      title: (
        <Space>
          <FileProtectOutlined style={{ fontSize: "25px" }} />
          <Link className="link" to="/secure-notes">Note</Link>
        </Space>
      ),
      count: 0,
    },
  ]);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Unable to retrieve current user information:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const currentUserData = await fetchCurrentUser();
      if (!currentUserData) {
        setLoading(false);
        return;
      }
      setCurrentUser(currentUserData);
      const userId = currentUserData.id || currentUserData.userId;

      const updateSummaryCard = (key, count) => {
        setSummaryCards((prevCards) =>
          prevCards.map((card) =>
            card.key === key ? { ...card, count } : card
          )
        );
      };

      const fetchUsers = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/api/invite/get-invited-users`,
            { userId },
            { withCredentials: true }
          );
          setUsers(response.data);
          updateSummaryCard("Users", response.data.length);
        } catch (error) {
          console.error("An error occurred while retrieving users:", error);
        }
      };

      const fetchSecretCountByUserId = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/api/secrets/get-user-secret-count`,
            { userId },
            { withCredentials: true }
          );
          const secretCount = response.data[0]?.secret_count || 0;
          updateSummaryCard("secrets", secretCount);
        } catch (error) {
          console.error("Error fetching secret count:", error);
        }
      };

      const fetchApps = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/apps/get-apps`, {
            withCredentials: true,
          });
          const appsCount = response.data.apps?.length || 0;
          updateSummaryCard("apps", appsCount);
        } catch (error) {
          console.error("Error fetching apps:", error);
        }
      };

      const fetchTeams = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/api/teams/get-teams-by-user-id`,
            { userId },
            { withCredentials: true }
          );
          const teamsCount = response.data.teams?.length || 0;
          updateSummaryCard("teams", teamsCount);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };

      const fetchEnvFiles = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/api/config-manager/get-env-files`,
            { withCredentials: true }
          );
          const configCount = response.data.envFiles?.length || 0;
          updateSummaryCard("envFiles", configCount);
        } catch (error) {
          console.error("Error fetching env files:", error);
        }
      };

      const fetchMySharedItems = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/api/share/get-my-shared-items`,
            { withCredentials: true }
          );
          const sharedCount = response.data.sharedItems?.length || 0;
          updateSummaryCard("shared", sharedCount);
        } catch (error) {
          console.error("Error fetching my shared items:", error);
        }
      };

      const fetchSecureNotes = async () => {
        try {
          const response = await axios.post(
            `${API_URL}/api/secure-note/get-secure-notes-by-user-id`,
            { userId },
            { withCredentials: true }
          );
          const noteCount = response.data?.length || 0;
          updateSummaryCard("note", noteCount);
        } catch (error) {
          console.error("Error fetching secure notes:", error);
        }
      };

      await Promise.all([
        fetchUsers(),
        fetchSecretCountByUserId(),
        fetchApps(),
        fetchTeams(),
        fetchEnvFiles(),
        fetchMySharedItems(),
        fetchSecureNotes(),
      ]);
      setLoading(false);
    };

    loadData();
  }, []);

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <style jsx>{`
        @media (max-width: 768px) {
          .ant-card {
            margin-bottom: 10px;
          }
        }
        @media (max-width: 576px) {
          .ant-row {
            flex-direction: column;
          }
        }
      `}</style>
      <Row gutter={[16, 16]}>
        {summaryCards.map((card) => (
          <Col xs={24} sm={12} md={8} key={card.key}>
            <Card>
              <Row justify="space-around" align="middle">
                <Col>
                  <Title level={4}>{card.title}</Title>
                </Col>
                <Col>
                  <Title
                    level={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {card.count}
                  </Title>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ marginTop: "20px" }}>
        <Card
          title={
            <Space>
              <UserOutlined style={{ fontSize: "20px" }} /> Users
            </Space>
          }
          bordered={false}
        >
          <Table
            columns={columns}
            dataSource={users}
            loading={loading}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 600 }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
