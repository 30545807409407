/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, Descriptions, Typography, Spin } from "antd";
import axios from "axios";

const { Title } = Typography;

const ProfilePage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Mevcut kullanıcı bilgileri alınamadı:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div style={{ padding: "24px" }}>
      <Title level={1}>Profile</Title>
      {loading ? (
        <Spin tip="Yükleniyor..." />
      ) : userData ? (
        <Card
          style={{
            width: "100%",
            maxWidth: 500,
            borderRadius: 10,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Descriptions
            column={1}
            labelStyle={{ fontWeight: "bold" }}
            items={[
              {
                label: "Name",
                children: userData.name,
              },
              {
                label: "Email",
                children: userData.email,
              },
              {
                label: "Role",
                children: userData.role,
              },
            ]}
          />
        </Card>
      ) : (
        <p>Kullanıcı bilgileri alınamadı.</p>
      )}
    </div>
  );
};

export default ProfilePage;
