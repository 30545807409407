/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Space,
  Empty,
  Table,
  Dropdown,
  message,
  Modal,
  Input,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  ShareAltOutlined,
  DownOutlined,
  MoreOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ShareModal from "../../components/share/ShareModal";
import SecureNoteNewModal from "./component/SecureNoteNewModal";
import SecureNoteUpdate from "./component/SecureNoteUpdate";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

const { Title } = Typography;
const { confirm } = Modal;

function SecureNotes() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateModalVisible, setCreateIsModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editNote, setEditNote] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [shareItems, setShareItems] = useState(null);
  const [userId, setUserId] = useState(null);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadCurrentUser = async () => {
      const currentUserData = await fetchCurrentUser();
      if (currentUserData) {
        const userIdFromToken = currentUserData.id || currentUserData.userId;
        setUserId(userIdFromToken);
      }
    };

    loadCurrentUser();
  }, []);

  useEffect(() => {
    const loadSecureNotes = async () => {
      if (!userId) return;

      try {
        const notesResponse = await fetchSecureNotes(userId);
        const notes = notesResponse.data
          .map((note) => ({
            key: note.note_id,
            name: note.title,
            note: note.note,
            lastUpdate: new Date(note.updated_at).toLocaleString(),
          }))
          .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));

        setData(notes);
        setFilteredData(notes);
      } catch (error) {
        console.error("Error loading data:", error);
        message.error("Failed to load data");
      }
    };

    loadSecureNotes();
  }, [userId, isCreateModalVisible, isEditModalVisible, isShareModalVisible]);

  const fetchSecureNotes = async () => {
    return await axios.post(
      `${API_URL}/api/secure-note/get-secure-notes-by-user-id`,
      { userId },
      {
        withCredentials: true,
      }
    );
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.post(
        `${API_URL}/api/secure-note/delete-secure-note`,
        { noteId },
        { withCredentials: true }
      );
      message.success("Note deleted successfully");

      setData((prevData) => prevData.filter((note) => note.key !== noteId));
      setFilteredData((prevData) =>
        prevData.filter((note) => note.key !== noteId)
      );
    } catch (error) {
      console.error("Failed to delete note:", error);
      message.error("Failed to delete note");
    }
  };

  const handleBulkDelete = () => {
    const selectedNotes = selectedRowKeys
      .map((key) => data.find((note) => note.key === key))
      .filter((note) => note);

    const noteNames = selectedNotes.map((note) => note.name).join(", ");

    confirm({
      title: "Are you sure you want to delete the selected notes?",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      content: (
        <span>
          <strong>{noteNames}</strong> notes will be deleted. This action cannot
          be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await axios.post(
            `${API_URL}/api/secure-note/delete-secure-note`,
            { noteId: selectedRowKeys },
            { withCredentials: true }
          );
          message.success("Notes deleted successfully");

          setData((prevData) =>
            prevData.filter((note) => !selectedRowKeys.includes(note.key))
          );
          setFilteredData((prevData) =>
            prevData.filter((note) => !selectedRowKeys.includes(note.key))
          );
          setSelectedRowKeys([]);
        } catch (error) {
          console.error("Bulk delete failed:", error);
          message.error("An error occurred during bulk deletion.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredNotes = data.filter((note) =>
      note.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredNotes);
  };

  const showDeleteConfirm = (note) => {
    confirm({
      title: `Are you sure you want to delete this note?`,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      content: (
        <span>
          You are about to delete the note: <strong>{note.name}</strong>. This
          action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteNote(note.key);
      },
    });
  };

  const handleNoteUpdateSuccess = (updatedNote) => {
    setData((prevData) =>
      prevData.map((note) =>
        note.key === updatedNote.key ? updatedNote : note
      )
    );

    setFilteredData((prevData) =>
      prevData.map((note) =>
        note.key === updatedNote.key ? updatedNote : note
      )
    );
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((note) => ({
      Name: note.name,
      "Last Update": note.lastUpdate,
      Collections: note.collections,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Secure Notes");

    const fileName = `secure_notes.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const showCreateNoteModal = () => setCreateIsModalVisible(true);

  const showBulkShareModal = () => {
    setShareItems(
      selectedRows.map((item) => ({
        id: item.key,
        type: "note",
      }))
    );
    setIsShareModalVisible(true);
  };

  const showSingleShareModal = (record) => {
    setShareItems([{ id: record.key, type: "note" }]);
    setIsShareModalVisible(true);
  };

  const showEditModal = (note) => {
    setEditNote(note);
    setIsEditModalVisible(true);
  };

  const columns = [
    {
      title: "Secure name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link onClick={() => showEditModal(record)}>{text}</Link>
      ),
    },
    {
      title: "Last update",
      dataIndex: "lastUpdate",
      key: "update",
    },
    {
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  icon: <DeleteOutlined style={{ color: "red" }} />,
                  label: <Space style={{ color: "red" }}>Delete</Space>,
                  onClick: () => showDeleteConfirm(record),
                },
                {
                  key: "2",
                  icon: <ShareAltOutlined />,
                  label: "Share",
                  onClick: () => showSingleShareModal(record),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button
              type="text"
              style={{
                color: selectedRowKeys.includes(record.key)
                  ? "white"
                  : "inherit",
              }}
            >
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const text = (
    <span>
      You can view the secure notes also share them with a team or user.
    </span>
  );

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={3}>
        Secure Notes{" "}
        <Tooltip placement="top" title={text}>
          <QuestionCircleOutlined style={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>

      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col xs={24} sm={12} md={12} lg={12}>
          <Space direction="horizontal" size="middle" wrap>
            <Button
              icon={<PlusOutlined />}
              onClick={showCreateNoteModal}
              type="primary"
            >
              Add new
            </Button>
            <Button
              onClick={showBulkShareModal}
              disabled={selectedRowKeys.length === 0}
              icon={<ShareAltOutlined />}
            >
              Share
            </Button>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    icon: <DeleteOutlined style={{ color: "red" }} />,
                    label: <Space style={{ color: "red" }}>Delete</Space>,
                    onClick: handleBulkDelete,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button disabled={selectedRowKeys.length === 0}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12}>
          <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
            <Col>
              <Input
                placeholder="Search..."
                className="search-input-table"
                prefix={<SearchOutlined />}
                value={searchValue}
                onChange={handleSearch}
                autoComplete="off"
              />
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "2",
                      icon: <ExportOutlined />,
                      label: "Export",
                      onClick: handleExport,
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button type="text">
                  <MoreOutlined style={{ fontSize: "20px" }} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ marginTop: 10 }}>
        {filteredData.length === 0 ? (
          <Empty description="No secure notes available" />
        ) : (
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.key}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scroll={{ x: "max-content" }}
          />
        )}
      </div>
      <SecureNoteNewModal
        isModalVisible={isCreateModalVisible}
        setIsModalVisible={setCreateIsModalVisible}
      />
      <SecureNoteUpdate
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        editNote={editNote}
        onSuccess={handleNoteUpdateSuccess}
      />
      <ShareModal
        visible={isShareModalVisible}
        onCancel={() => {
          setIsShareModalVisible(false);
          setShareItems(null);
        }}
        userId={userId}
        items={
          shareItems ||
          selectedRows.map((item) => ({
            id: item?.key,
            type: "note",
          }))
        }
      />
    </div>
  );
}

export default SecureNotes;
