/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Typography,
  Space,
  Button,
  Input,
  Empty,
  Dropdown,
  Row,
  Col,
  Modal,
  Form,
  message,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  SearchOutlined,
  DownOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./index.css";

const { Title, Text } = Typography;
const initialDataSource = [];

const ConfigManager = ({ isDarkMode }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState(initialDataSource);
  const [filteredData, setFilteredData] = useState(initialDataSource);
  const [envFileName, setEnvFileName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [viewEnv, setViewEnv] = useState(null);
  const [editingEnv, setEditingEnv] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    fetchEnvFiles();
  }, []);

  const fetchEnvFiles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/config-manager/get-env-files`,
        {
          withCredentials: true,
        }
      );
      const envFiles = response.data.envFiles.map((file) => ({
        ...file,
        key: file.id,
        lastUpdate: new Date(
          file.updated_at || file.created_at
        ).toLocaleString(),
      }));
      setDataSource(envFiles);
      setFilteredData(envFiles);
    } catch (error) {
      console.error("Error fetching env files:", error);
      message.error("Failed to load config data.");
    }
  };

  const handleCreateEnv = async (values) => {
    const lines = values?.envFileContent?.split("\n");
    const result = {};
    lines.forEach((line) => {
      const [key, value] = line.split("=");
      result[key] = value;
    });
    setIsCreate(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/config-manager/add-env-file`,
        {
          envFileName: values.envFileName,
          envFile: result || {},
        },
        {
          withCredentials: true,
        }
      );
      message.success(response.data.message || "Env file created successfully");

      setIsModalVisible(false);
      createForm.resetFields();

      fetchEnvFiles();
    } catch (error) {
      console.error("Error creating env file:", error);
      message.error("Failed to create env file");
    } finally {
      setIsCreate(false);
    }
  };

  const handleEditEnv = (record) => {
    setEditingEnv(record);
    let fileContent = {};
    try {
      fileContent = getCleanContent(record.env_file);
    } catch {
      fileContent = record.env_file;
    }

    editForm.setFieldsValue({
      envFileName: record.env_file_name,
      envFileContent: fileContent,
    });
    setIsEditModalVisible(true);
  };

  const handleUpdateEnv = async (values) => {
    if (!editingEnv) return;
    setIsEdit(true);
    const lines = values?.envFileContent?.split("\n");
    const result = {};
    lines.forEach((line) => {
      const [key, value] = line.split("=");
      result[key] = value;
    });

    try {
      await axios.put(
        `${API_URL}/api/config-manager/update-env-file`,
        {
          envFileId: editingEnv.id,
          envFileName: values.envFileName,
          envFile: result || {},
        },
        {
          withCredentials: true,
        }
      );
      message.success("Env file updated successfully");

      setIsEditModalVisible(false);
      editForm.resetFields();
      setEditingEnv(null);

      fetchEnvFiles();
    } catch (error) {
      console.error("Error updating env file:", error);
      message.error("Failed to update env file");
    } finally {
      setIsEdit(false);
    }
  };

  const handleDeleteEnv = (envFileId, envFileName) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${envFileName}"?`,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.post(
            `${API_URL}/api/config-manager/delete-env-file`,
            { envFileId },
            {
              withCredentials: true,
            }
          );

          message.success(`"${envFileName}" deleted successfully.`);
          fetchEnvFiles();
        } catch (error) {
          console.error("Error deleting env file:", error);
          message.error(`Failed to delete "${envFileName}".`);
        }
      },
      onCancel() {
        message.info("Deletion cancelled");
      },
    });
  };

  const handleBulkDelete = () => {
    if (!selectedRowKeys.length) {
      message.warning("Please select at least one file to delete.");
      return;
    }

    const selectedFiles = dataSource.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    const selectedNames = selectedFiles
      .map((item) => item.env_file_name)
      .join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected env files?",
      content: (
        <span>
          The following items will be deleted:{" "}
          <Text strong>{selectedNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Promise.all(
            selectedRowKeys.map((envFileId) =>
              axios.post(
                `${API_URL}/api/config-manager/delete-env-file`,
                { envFileId },
                {
                  withCredentials: true,
                }
              )
            )
          );
          message.success("Selected env files deleted successfully.");
          setSelectedRowKeys([]);
          fetchEnvFiles();
        } catch (error) {
          console.error("Error occurred during bulk deletion:", error);
          message.error("Bulk deletion failed.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filtered = dataSource.filter((item) =>
      item.env_file_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const openCreateModal = () => {
    setIsModalVisible(true);
  };
  const closeCreateModal = () => {
    setIsModalVisible(false);
    createForm.resetFields();
  };
  const closeEditModal = () => {
    setIsEditModalVisible(false);
    editForm.resetFields();
    setEditingEnv(null);
  };

  const handleViewEnv = (record) => {
    setViewEnv(record);
    setIsViewModalVisible(true);
  };

  const closeViewModal = () => {
    setIsViewModalVisible(false);
    setViewEnv(null);
  };

  const handleCopyCurl = (record) => {
    if (record?.curl_command) {
      navigator.clipboard
        .writeText(record.curl_command)
        .then(() => message.success("Curl command copied!"))
        .catch(() => message.error("Copy failed!"));
    } else {
      message.warning("No curl command available!");
    }
  };

  const handleEnvCopy = () => {
    if (viewEnv?.env_file) {
      const cleanContent = getCleanContent(viewEnv.env_file);
      navigator.clipboard.writeText(cleanContent).then(
        () => {
          message.success("Env File Content copied to clipboard!");
        },
        (err) => {
          message.error("Failed to copy content!");
          console.error(err);
        }
      );
    } else {
      message.warning("No content available to copy!");
    }
  };

  const getCleanContent = (content = {}) => {
    const parsedContent = JSON.parse(content);
    const envContent = Object.entries(parsedContent)
      .map(([key, value]) => `${key}=${value}`)
      .join("\n");

    return envContent;
  };

  const columns = [
    {
      title: "Env File Name",
      dataIndex: "env_file_name",
      key: "env_file_name",
      render: (text, record) => (
        <Link onClick={() => handleViewEnv(record)}>{text}</Link>
      ),
    },
    {
      title: "Curl Command",
      dataIndex: "curl_command",
      key: "curl_command",
      render: (text, record) => (
        <Space>
          <Typography.Text
            style={{
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {"********"}
          </Typography.Text>
          <Tooltip title="Copy Curl">
            <Button
              icon={<CopyOutlined />}
              type="text"
              onClick={() => handleCopyCurl(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Last update",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
    },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  icon: <EditOutlined />,
                  label: "Edit",
                  onClick: () => handleEditEnv(record),
                },
                {
                  key: "2",
                  icon: <DeleteOutlined style={{ color: "red" }} />,
                  label: <Space style={{ color: "red" }}>Delete</Space>,
                  onClick: () =>
                    handleDeleteEnv(record.id, record.env_file_name),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const text = (
    <span>
      You can add .env configurations and retrieve them using a cURL command to use them wherever needed.
    </span>
  );

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>
        Config Manager (Env Files){" "}
        <Tooltip placement="top" title={text}>
          <QuestionCircleOutlined style={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          {/* Left side buttons */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Button
                icon={<PlusOutlined />}
                onClick={openCreateModal}
                type="primary"
              >
                Create Env File
              </Button>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      icon: <DeleteOutlined style={{ color: "red" }} />,
                      label: <Space style={{ color: "red" }}>Delete</Space>,
                      onClick: handleBulkDelete,
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button disabled={selectedRowKeys.length === 0}>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 200 }}
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {filteredData.length === 0 ? (
          <Empty description="No env files available" />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            rowSelection={rowSelection}
            scroll={{ x: "max-content" }}
          />
        )}
      </Space>

      {/* Create Modal */}
      <Modal
        title="Create Env File"
        open={isModalVisible}
        onCancel={closeCreateModal}
        footer={null}
      >
        <Form
          form={createForm}
          onValuesChange={(changedValues, allValues) => {
            if (changedValues.envFileName) {
              setEnvFileName(changedValues.envFileName);
            }
          }}
          onFinish={handleCreateEnv}
          layout="vertical"
        >
          <Form.Item
            name="envFileName"
            label="Env File Name"
            rules={[
              { required: true, message: "Please enter env file name!" },
              {
                pattern: /^\.env(\.\S+)?$/,
                message:
                  "Env file name must be '.env' or start with '.env.' and cannot contain spaces",
              },
            ]}
            extra={
              <div
                style={{
                  marginTop: 10,
                  color: isDarkMode ? "#cdcdcd" : "inherit",
                }}
              >
                example:
                <pre
                  style={{
                    background: isDarkMode ? "#394867" : "#f6f6f6",
                    padding: "8px",
                    borderRadius: "4px",
                    color: isDarkMode ? "#cdcdcd" : "inherit",
                  }}
                >
                  File name: <code>.env.{"nameYouProvided"}</code>
                </pre>
              </div>
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="envFileContent"
            label="Env File Content"
            rules={[
              { required: true, message: "Please enter env file content!" },
            ]}
            extra={
              <div
                style={{
                  marginTop: 10,
                  color: isDarkMode ? "#cdcdcd" : "inherit",
                }}
              >
                example:
                <pre
                  style={{
                    background: isDarkMode ? "#394867" : "#f6f6f6",
                    padding: "8px",
                    borderRadius: "4px",
                    color: isDarkMode ? "#cdcdcd" : "inherit",
                  }}
                >
                  {`# .env file example
          DB_HOST=localhost
          DB_USER=root
          DB_PASS=password`}
                </pre>
              </div>
            }
          >
            <Input.TextArea rows={4} autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isCreate}
              loading={isCreate}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Edit Env File"
        open={isEditModalVisible}
        onCancel={closeEditModal}
        footer={null}
      >
        <Form form={editForm} onFinish={handleUpdateEnv} layout="vertical">
          <Form.Item
            name="envFileName"
            label="Env File Name"
            rules={[
              { required: true, message: "Please enter env file name!" },
              {
                pattern: /^\.env(\.\S+)?$/,
                message:
                  "Env file name must be '.env' or start with '.env.' and cannot contain spaces",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="envFileContent"
            label="Env File Content"
            tooltip="You can insert JSON or text content here"
          >
            <Input.TextArea rows={4} autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isEdit}
              loading={isEdit}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title={`Env File: ${viewEnv?.env_file_name}`}
        open={isViewModalVisible}
        onCancel={closeViewModal}
        width={820}
        footer={[
          <Button key="close" onClick={closeViewModal}>
            Close
          </Button>,
        ]}
        style={{ top: 50 }}
      >
        {viewEnv && (
          <div className="view-container">
            <div className="view-content">
              <pre
                className="view-pre"
                style={{ background: isDarkMode ? "#394867" : "#f6f6f6" }}
              >
                <Typography.Text strong>
                  {getCleanContent(viewEnv.env_file)}
                </Typography.Text>
              </pre>
            </div>
            <Button
              className="copy-btn"
              icon={<CopyOutlined />}
              onClick={handleEnvCopy}
            >
              Copy
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ConfigManager;
