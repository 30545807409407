/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Button, Input, message, Typography } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "./AuthForm.css";

const { Title } = Typography;

const Verify = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [timeLeft, setTimeLeft] = useState(120);
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isVerify, setIsVerify] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(3);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const location = useLocation();
  const userEmail = location.state?.email || "";

  useEffect(() => {
    if (timeLeft <= 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60).toString().padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${m}:${s}`;
  };

  const handleInputChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !verificationCode[index]) {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    if (timeLeft <= 0) {
      setErrorMessage("Time expired. Please resend the code");
      return;
    }
    setIsVerify(true);
    const code = verificationCode.join("");
    if (code.length !== 6) {
      setErrorMessage("Please enter the 6-digit verification code.");
      setIsVerify(false);
      return;
    }
    try {
      const response = await fetch(`${API_URL}/api/auth/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ verificationCode: code, email: userEmail }),
      });
      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message || "Email successfully verified!");
        setErrorMessage(null);
        setAttemptsLeft(3);
        navigate("/dashboard");
        message.success("Email successfully verified!");
      } else {
        const data = await response.json();
        const remainingAttempts = attemptsLeft - 1;
        if (remainingAttempts <= 0) {
          setSuccessMessage("Too many incorrect attempts. A new verification code has been sent to your email.");
          setErrorMessage(null);
          setAttemptsLeft(3);
          setTimeLeft(120);
          setVerificationCode(Array(6).fill(""));
          await handleResend();
        } else {
          setAttemptsLeft(remainingAttempts);
          setErrorMessage(`Incorrect code. You have ${remainingAttempts} attempts left.`);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsVerify(false);
    }
  };

  const handleResend = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/verify-resend`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email: userEmail }),
      });
      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message || "New verification code sent!");
        setErrorMessage(null);
        setTimeLeft(120);
        setVerificationCode(Array(6).fill(""));
        setAttemptsLeft(3);
      } else {
        const data = await response.json();
        setErrorMessage(data.error || "Code could not be resent.");
        setSuccessMessage(null);
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage(null);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <form className="verification-form" onSubmit={handleVerify}>
        <img src="/logo2.png" alt="Logo" className="logo" />
        <Title level={3} className="title">
          Email Verification
        </Title>
        <div className="verification-inputs">
          {verificationCode.map((digit, index) => (
            <Input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              style={{ width: 40, textAlign: "center" }}
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              autoComplete="off"
              inputMode="numeric"
              pattern="\d*"
            />
          ))}
        </div>
        <p>Time: {formatTime(timeLeft)}</p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {timeLeft > 0 ? (
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={isVerify}
            loading={isVerify}
          >
            Verify
          </Button>
        ) : (
          <Button type="primary" block onClick={handleResend}>
            Resend Code
          </Button>
        )}
        <Button type="text" onClick={handleBack}>
          Back
        </Button>
      </form>
    </div>
  );
};

export default Verify;
