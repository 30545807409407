import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Form, Input, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import "./AuthForm.css";

const { Title } = Typography;

function Register() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setSignupEmail] = useState("");
  const [isRegister, setIsRegister] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsRegister(true);
    try {
      if (values.password !== values.confirmPassword) {
        message.error("Passwords do not match!");
        return;
      }

      await axios.post(
        `${API_URL}/api/auth/register`,
        {
          name: values.username,
          email: email,
          password: values.password,
          role: "user",
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );

      navigate("/verify", { state: { email: values.email } });
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "An error occurred. Please try again.";
      message.error(errorMsg);
    } finally {
      setIsRegister(false);
    }
  };

  return (
    <div className="auth-container">
      <Form
        form={form}
        name="signupForm"
        layout="vertical"
        className="auth-form"
        onFinish={onFinish}
      >
        <div className="logo-container">
          <img src="/logo2.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="form-title">
          Signup
        </Title>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="Enter your username" />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setSignupEmail(e.target.value)}
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            { min: 9, message: "Password must be at least 9 characters!" },
            {
              pattern: /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,])/,
              message:
                "Password must include at least one uppercase letter, one number, and one special character!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Enter your password"
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm your password"
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="submit-btn"
            disabled={isRegister}
            loading={isRegister}
          >
            Signup Now
          </Button>
        </Form.Item>

        <Divider plain className="divider">
          OR
        </Divider>

        <Form.Item>
          <Button
            type="default"
            block
            className="toggle-btn"
            onClick={() => navigate("/login")}
          >
            Switch to Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Register;
