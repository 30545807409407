import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import { notification, Button, Space } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PasswordManager from "./Pages/passwordManager/PasswordManager";
import LoadingPage from "./components/Loadingpage/LoadingPage";
import LayoutMain from "./components/layout/LayoutMain";
import Verify from "./Pages/auth/Verify";
import UserManagamentPage from "./Pages/userManagament/UserManagamentPage";
import Apps from "./Pages/apps/Apps";
import Folders from "./Pages/folders/Folders";
import Builder from "./Pages/builder/Builder";
import Password from "./Pages/Settings/passwordChange/PasswordChange";
import SecureNotes from "./Pages/secureNotes/SecureNotes";
import ForgotPassword from "./Pages/auth/ForgotPassword";
import SharedItems from "./Pages/sharedItems/SharedItems";
import Profile from "./Pages/profile/Profile";
import NotFound from "./components/notFound/NotFound";
import ConfigManager from "./Pages/configManager/ConfigManager";
import ResetPassword from "./Pages/auth/ResetPassword";
import Login from "./Pages/auth/Login";
import Register from "./Pages/auth/Register";
import axios from "axios";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const checkAuthentication = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/me`,
        {
          withCredentials: true,
        }
      );
      if (response.data && (response.data.id || response.data.userId)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Authentication check failed:", error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const TOKEN_NOTIFICATION_KEY = "tokenExpiredNotification";
  let tokenNotificationActive = false;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!isAuthenticated) {
        return Promise.reject(error);
      }
      const excludedPaths = [
        "/login",
        "/register",
        "/verify",
        "/forgot-password",
        "/reset-password",
      ];
      if (
        excludedPaths.some((path) =>
          window.location.pathname.toLowerCase().startsWith(path)
        )
      ) {
        return Promise.reject(error);
      }
      if (error.response && error.response.status === 401) {
        if (!tokenNotificationActive) {
          tokenNotificationActive = true;
          const btn = (
            <Space>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  notification.destroy(TOKEN_NOTIFICATION_KEY);
                  tokenNotificationActive = false;
                  window.location.href = "/login";
                }}
              >
                Confirm
              </Button>
            </Space>
          );

          notification.open({
            key: TOKEN_NOTIFICATION_KEY,
            message: "Session Expired",
            description:
              "You need to log in. Click the 'Confirm' button to continue.",
            btn,
            duration: 0,
            closeIcon: null,
            onClose: () => {
              tokenNotificationActive = false;
            },
          });
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const path = window.location.pathname;
    if (
      path === "/login" ||
      path === "/register" ||
      path === "/verify" ||
      path === "/forgot-password" ||
      path === "/reset-password"
    ) {
      setIsLoading(false);
      return;
    }
    checkAuthentication();
  }, []);

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#14274E",
          borderRadius: 5,
        },
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              !isAuthenticated ? (
                <Login onLoginSuccess={handleLoginSuccess} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/register"
            element={
              !isAuthenticated ? (
                <Register onLoginSuccess={handleLoginSuccess} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot-Password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Dashboard />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Dashboard />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/apps"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Apps />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/apps/password-manager"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <PasswordManager />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/config-manager"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <ConfigManager />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/shared-items"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <SharedItems />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/secure-notes"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <SecureNotes />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/user-management"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <UserManagamentPage />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/settings/Password"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Password />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/builder"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Builder />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/apps/:appId"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <PasswordManager />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/apps/:appId/:folderId"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Folders />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <LayoutMain onLogout={handleLogout}>
                  <Profile />
                </LayoutMain>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
