/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Input,
  Button,
  Checkbox,
  Radio,
  Typography,
  Form,
  Space,
  Tooltip,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  ReloadOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { faker } from "@faker-js/faker";
import "./Builder.css";

const { Title } = Typography;

function Builder() {
  // Password Kısmı
  const [passwordType, setPasswordType] = useState("password");
  const [wordCount, setWordCount] = useState(3);
  const [separator, setSeparator] = useState("-");
  const [length, setLength] = useState(14);
  const [minNumbers, setMinNumbers] = useState(0);
  const [minSpecialChars, setMinSpecialChars] = useState(0);
  const [includeUpperCase, setIncludeUpperCase] = useState(true);
  const [includeLowerCase, setIncludeLowerCase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includeSymbols, setIncludeSymbols] = useState(false);
  const [useUppercase, setUseUppercase] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [password, setPassword] = useState("");

  const generatePassword = () => {
    let generatedPassword = "";

    if (passwordType === "password") {
      let charset = "";
      let numbers = "";
      let symbols = "";
      if (includeUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      if (includeLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
      if (includeNumbers) numbers = "0123456789";
      if (includeSymbols) symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

      for (let i = 0; i < minNumbers; i++) {
        generatedPassword += numbers.charAt(
          Math.floor(Math.random() * numbers.length)
        );
      }

      for (let i = 0; i < minSpecialChars; i++) {
        generatedPassword += symbols.charAt(
          Math.floor(Math.random() * symbols.length)
        );
      }

      const combinedCharset = charset + numbers + symbols;
      for (
        let i = generatedPassword.length, n = combinedCharset.length;
        i < length;
        ++i
      ) {
        generatedPassword += combinedCharset.charAt(
          Math.floor(Math.random() * n)
        );
      }

      generatedPassword = generatedPassword
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
    } else if (passwordType === "passphrase") {
      const words = [];
      const randomIndex = Math.floor(Math.random() * wordCount);

      for (let i = 0; i < wordCount; i++) {
        let word = faker.word.noun();
        if (useUppercase) {
          word = word.charAt(0).toUpperCase() + word.slice(1);
        }
        if (addNumber && i === randomIndex) {
          const randomNumber = Math.floor(Math.random() * 100);
          word = `${word}${randomNumber}`;
        }
        words.push(word);
      }
      generatedPassword = words.join(separator);
    }

    setPassword(generatedPassword);
  };

  const handleLengthChange = (e) => {
    const value = Math.max(0, Math.min(50, Number(e.target.value)));
    setLength(value);
    if (value < 2) {
      setMinNumbers(0);
      setMinSpecialChars(0);
    }
  };

  const handleIncludeNumbersChange = (e) => {
    const isChecked = e.target.checked;
    setIncludeNumbers(isChecked);
    if (!isChecked) {
      setMinNumbers(0);
    } else {
      setMinNumbers(1);
    }
  };

  const handleIncludeSymbolsChange = (e) => {
    const isChecked = e.target.checked;
    setIncludeSymbols(isChecked);
    if (!isChecked) {
      setMinSpecialChars(0);
    } else {
      setMinSpecialChars(1);
    }
  };

  const handleMinNumbersChange = (e) => {
    const inputValue = Math.max(0, parseInt(e.target.value, 10));
    const value =
      length < 2 ? 0 : Math.min(inputValue, length - 1 - minSpecialChars);
    setMinNumbers(value);
  };

  const handleMinSpecialCharsChange = (e) => {
    const inputValue = Math.max(0, parseInt(e.target.value, 10));
    const value =
      length < 2 ? 0 : Math.min(inputValue, length - 1 - minNumbers);
    setMinSpecialChars(value);
  };

  // Username Kısmı
  const [username, setUsername] = useState("");
  const [usernameType, setUsernameType] = useState("randomWord");
  const [email, setEmail] = useState("");
  const [domainInput, setDomainInput] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  const generateRandomString = (length = 8) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const number = "0123456789";
    const combinedCharset = charset + number;
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * combinedCharset.length);
      randomString += combinedCharset[randomIndex];
    }

    return randomString;
  };

  const generatePlusEmailUsername = () => {
    if (!email.includes("@")) {
      message.error("Please enter a valid email address!");
      setUsername("-");
      return;
    }

    const [namePart, domainPart] = email.split("@");
    const randomWord = generateRandomString(5);
    const newUsername = `${namePart}+${randomWord}@${domainPart}`;

    setUsername(newUsername);
  };

  const generateCatchAllUsername = () => {
    if (domainInput.trim()) {
      const randomWord = generateRandomString(5);
      const newUsername = `${randomWord}@${domainInput}`;
      setUsername(newUsername);
    } else {
      message.error("Please enter a domain name!");
      setUsername("-");
    }
  };

  const generateMaskedEmailUsername = async () => {
    const randomWord = generateRandomString(5);
    const newUsername = `${randomWord}@firefoxrelay.com`;
    setUsername(newUsername);
  };

  const handleGenerateUsername = async () => {
    generateUsername();
  };

  const generateUsername = async () => {
    switch (usernameType) {
      case "plusEmail":
        generatePlusEmailUsername();
        break;
      case "catchAll":
        generateCatchAllUsername();
        break;
      case "maskedEmail":
        await generateMaskedEmailUsername();
        break;
      default:
        let newUsername = faker.word.noun();
        if (addNumber) {
          newUsername += Math.floor(Math.random() * 9000 + 1000);
        }
        if (useUppercase) {
          newUsername =
            newUsername.charAt(0).toUpperCase() + newUsername.slice(1);
        }
        setUsername(newUsername);
        break;
    }
  };

  useEffect(() => {
    generatePassword();
  }, [
    passwordType,
    wordCount,
    separator,
    length,
    minNumbers,
    minSpecialChars,
    includeUpperCase,
    includeLowerCase,
    includeNumbers,
    includeSymbols,
    useUppercase,
    addNumber,
  ]);

  useEffect(() => {
    if (usernameType !== "randomWord") {
      generateUsername();
    }
  }, [usernameType, email, domainInput, useUppercase, addNumber]);

  useEffect(() => {
    if (activeTab === "2" && usernameType === "randomWord") {
      generateUsername();
    }
  }, [activeTab, usernameType, useUppercase, addNumber]);

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);

  const passwordQuest = (
    <span>
      The Password Generator allows you to create secure passwords based on customizable criteria.
    </span>
  );

  const userQuest = (
    <span>
      The Username Generator allows you to create unique usernames based on customizable criteria.
    </span>
  );

  const items = [
    {
      key: "1",
      label: "Password",
      children: (
        <div>
          <Title level={2}>
            Password Generator{" "}
            <Tooltip placement="top" title={passwordQuest}>
              <QuestionCircleOutlined style={{ fontSize: "16px" }} />
            </Tooltip>
          </Title>

          <Space style={{ marginBottom: "16px" }}>
            <Input
              value={password}
              readOnly
              size="large"
              autoComplete="off"
              style={{ width: "600px" }}
            />
            <Button
              onClick={generatePassword}
              icon={<ReloadOutlined />}
              type="text"
              style={{ fontSize: "20px" }}
            />
            <Button
              onClick={() => navigator.clipboard.writeText(password)}
              icon={<CopyOutlined />}
              type="text"
              style={{ fontSize: "20px" }}
            />
          </Space>

          <Form layout="vertical" className="forms">
            <Form.Item label="Password type">
              <Radio.Group
                value={passwordType}
                onChange={(e) => setPasswordType(e.target.value)}
              >
                <Radio value="password">Password</Radio>
                <Radio value="passphrase">Long word</Radio>
              </Radio.Group>
            </Form.Item>

            {passwordType === "password" && (
              <>
                <Form.Item label="Length">
                  <Input
                    type="number"
                    value={length}
                    onChange={handleLengthChange}
                    max={50}
                    min={0}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item label="Min password length">
                  <Input value={length} readOnly disabled />
                </Form.Item>
                <Form.Item label="Least number">
                  <Input
                    type="number"
                    value={minNumbers}
                    onChange={handleMinNumbersChange}
                    max={length - 1 - minSpecialChars}
                    min={0}
                    disabled={!includeNumbers}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item label="Min special characters">
                  <Input
                    type="number"
                    value={minSpecialChars}
                    onChange={handleMinSpecialCharsChange}
                    max={length - 1 - minNumbers}
                    min={0}
                    disabled={!includeSymbols}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item label="Options">
                  <Checkbox
                    checked={includeUpperCase}
                    onChange={(e) => setIncludeUpperCase(e.target.checked)}
                  >
                    A-Z
                  </Checkbox>
                  <Checkbox
                    checked={includeLowerCase}
                    onChange={(e) => setIncludeLowerCase(e.target.checked)}
                  >
                    a-z
                  </Checkbox>
                  <Checkbox
                    checked={includeNumbers}
                    onChange={handleIncludeNumbersChange}
                  >
                    0-9
                  </Checkbox>
                  <Checkbox
                    checked={includeSymbols}
                    onChange={handleIncludeSymbolsChange}
                  >
                    !@#$%^&*
                  </Checkbox>
                </Form.Item>
              </>
            )}

            {passwordType === "passphrase" && (
              <>
                <Form.Item label="Kelime sayısı">
                  <Input
                    type="number"
                    value={wordCount}
                    onChange={(e) => setWordCount(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item label="Kelime ayırıcı">
                  <Input
                    value={separator}
                    onChange={(e) => setSeparator(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item label="Options">
                  <Checkbox
                    checked={useUppercase}
                    onChange={(e) => setUseUppercase(e.target.checked)}
                  >
                    Capitalize initials
                  </Checkbox>
                  <Checkbox
                    checked={addNumber}
                    onChange={(e) => setAddNumber(e.target.checked)}
                  >
                    Add number
                  </Checkbox>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: "Username",
      children: (
        <div>
          <Title level={2}>
            Username Generator{" "}
            <Tooltip placement="top" title={userQuest}>
              <QuestionCircleOutlined style={{ fontSize: "16px" }} />
            </Tooltip>
          </Title>
          <Space style={{ marginBottom: "16px" }}>
            <Input
              value={username}
              readOnly
              size="large"
              style={{ width: "600px" }}
            />

            <Button
              onClick={handleGenerateUsername}
              icon={<ReloadOutlined />}
              type="text"
              style={{ fontSize: "20px" }}
            />
            <Button
              onClick={() => navigator.clipboard.writeText(username)}
              icon={<CopyOutlined />}
              type="text"
              style={{ fontSize: "20px" }}
            />
          </Space>

          <Form layout="vertical" className="userForm">
            <Form.Item label="Username type">
              <Radio.Group
                value={usernameType}
                onChange={(e) => setUsernameType(e.target.value)}
              >
                <Space direction="vertical" className="usersForm-radio">
                  <Radio value="plusEmail">
                    Plus addressing email
                    <Tooltip title="Use your email provider’s plus addressing features.">
                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </Radio>
                  <Radio value="catchAll">
                    Catch-all email
                    <Tooltip title="Use your domain’s catch-all email address.">
                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </Radio>
                  <Radio value="randomWord">Random word</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            {usernameType === "randomWord" && (
              <Form.Item label="Options">
                <Checkbox
                  checked={useUppercase}
                  onChange={(e) => setUseUppercase(e.target.checked)}
                >
                  Capitalize initials
                </Checkbox>
                <Checkbox
                  checked={addNumber}
                  onChange={(e) => setAddNumber(e.target.checked)}
                >
                  Add number
                </Checkbox>
              </Form.Item>
            )}
            {usernameType === "catchAll" && (
              <Form.Item label="Domain">
                <Input
                  value={domainInput}
                  onChange={(e) => setDomainInput(e.target.value)}
                  size="large"
                  placeholder="example.com"
                  className="service-input"
                  autoComplete="off"
                />
              </Form.Item>
            )}

            {usernameType === "plusEmail" && (
              <Form.Item label="Email Address">
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="large"
                  className="service-input"
                  autoComplete="off"
                />
              </Form.Item>
            )}
          </Form>
        </div>
      ),
    },
  ];

  return (
    <Tabs
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key)}
      items={items}
    />
  );
}

export default Builder;
