import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const EditSecretModal = ({
  isEditModalVisible,
  setIsEditModalVisible,
  editingSecret,
  onSecretUpdated,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [editForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (editingSecret) {
      editForm.setFieldsValue({
        name: editingSecret.name,
        value: editingSecret.value,
      });
    }
  }, [editingSecret, editForm]);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Mevcut kullanıcı bilgileri alınamadı:", error);
      return null;
    }
  };

  const handleUpdateSecretBySecretId = async (secretId, values) => {
    const currentUserData = await fetchCurrentUser();
    if (!currentUserData) {
      return;
    }
    const userId = currentUserData.id || currentUserData.userId;
    setIsEdit(true);
    try {
      await axios.put(
        `${API_URL}/api/secrets/update-secret-by-secret-id`,
        { ...values, secretId, userId },
        {
          withCredentials: true,
        }
      );
      editForm.resetFields();
      setIsEditModalVisible(false);
      onSecretUpdated && onSecretUpdated();
      message.success("Secret updated successfully");
    } catch (error) {
      console.error("Error updating secret:", error);
      message.error("Failed to update secret");
    } finally {
      setIsEdit(false);
    }
  };

  return (
    <Modal
      title="Edit Secret"
      open={isEditModalVisible}
      onCancel={() => setIsEditModalVisible(false)}
      footer={null}
    >
      <Form
        form={editForm}
        onFinish={(values) =>
          handleUpdateSecretBySecretId(editingSecret.secret_id, values)
        }
      >
        <Form.Item
          name="name"
          label="Secret Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the secret!",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="value"
          label="Secret Value"
          rules={[
            {
              required: true,
              message: "Please input the value of the secret!",
            },
          ]}
        >
          <Input type="password" autoComplete="off" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isEdit}
            disabled={isEdit}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSecretModal;
