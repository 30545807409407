/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Card,
  Typography,
  Space,
  Button,
  Modal,
  Form,
  Input,
  message,
  Dropdown,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  UserAddOutlined,
  MailOutlined,
  DeleteOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const UserManagement = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] =
    useState(false);
  const [isRegisterUserModalVisible, setIsRegisterUserModalVisible] =
    useState(false);
  const [createUserForm] = Form.useForm();
  const [registerUserForm] = Form.useForm();
  const [userId, setUserId] = useState(null);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const currentUserData = await fetchCurrentUser();
      if (!currentUserData) {
        return;
      }
      const userIdFromToken = currentUserData.id || currentUserData.userId;
      setUserId(userIdFromToken);
      fetchUsers(userIdFromToken);
    };

    initialize();
  }, []);

  const fetchUsers = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/invite/get-invited-users`,
        { userId },
        {
          withCredentials: true,
        }
      );
      setDataSource(response.data);
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const showCreateUserModal = () => {
    setIsCreateUserModalVisible(true);
  };

  const showRegisterUserModal = () => {
    setIsRegisterUserModalVisible(true);
  };

  const handleCreateUserCancel = () => {
    setIsCreateUserModalVisible(false);
    createUserForm.resetFields();
  };

  const handleRegisterUserCancel = () => {
    setIsRegisterUserModalVisible(false);
    registerUserForm.resetFields();
  };

  const handleCreateUser = async (values) => {
    setIsCreate(true);
    try {
      await axios.post(`${API_URL}/api/invite/create-invited-user`, values, {
        withCredentials: true,
      });
      if (userId) {
        fetchUsers(userId);
      }
      setIsCreateUserModalVisible(false);
      createUserForm.resetFields();
      message.success("Invited user created successfully. Email sent.");
    } catch (error) {
      console.error("Error inviting user:", error);
      message.error("An error occurred while inviting the user.");
    } finally {
      setIsCreate(false);
    }
  };

  const handleRegisterUser = async (values) => {
    setIsCreate(true);
    try {
      await axios.post(
        `${API_URL}/api/invite/invite-registered-user`,
        { email: values.email },
        {
          withCredentials: true,
        }
      );
      if (userId) {
        fetchUsers(userId);
      }
      message.success("Invitation email successfully sent to registered user.");
      setIsRegisterUserModalVisible(false);
      registerUserForm.resetFields();
    } catch (error) {
      console.error(
        "An error occurred while sending an invitation to a registered user:",
        error.response?.data || error.message
      );
      message.error(
        "An error occurred while sending an invitation to a registered user."
      );
    } finally {
      setIsCreate(false);
    }
  };

  const handleDeleteUser = (user) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: (
        <span>
          The user <strong>{user.name}</strong> will be deleted. This action
          cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.post(
            `${API_URL}/api/invite/remove-invitation`,
            { userId: user.id },
            {
              withCredentials: true,
            }
          );
          setDataSource((prevDataSource) =>
            prevDataSource.filter((u) => u.id !== user.id)
          );
          message.success("User deleted successfully.");
        } catch (error) {
          console.error("An error occurred while deleting the user:", error);
          message.error("An error occurred while deleting the user.");
        }
      },
    });
  };

  const columns = [
    { title: "User Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      key: "actions",
      render: (text, record) => (
        <Space size="middle" style={{ float: "right" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  icon: (
                    <DeleteOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  ),
                  label: (
                    <Space
                      style={{
                        color: "red",
                      }}
                    >
                      Delete
                    </Space>
                  ),
                  onClick: () => handleDeleteUser(record),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const text = (
    <span>
      In User Management, you can add people to share your secrets, folders, and secure notes securely. 
      Easily manage access and control who can view or edit your sensitive data.
    </span>
  );

  return (
    <div>
      <Title level={2}>
        User Management{" "}
        <Tooltip placement="top" title={text}>
          <QuestionCircleOutlined style={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card
          title={
            <Row
              gutter={[4, 4]}
              justify="space-between"
              align="middle"
              wrap
              style={{ marginBottom: 10 }}
            >
              <Col
                xs={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Title
                  level={5}
                  style={{ margin: 0, lineHeight: "32px", width: "100%" }}
                >
                  Invited Users
                </Title>
              </Col>
              <Col
                xs={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="button-col"
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        icon: <UserAddOutlined />,
                        label: "Invite User",
                        onClick: showCreateUserModal,
                      },
                      {
                        key: "2",
                        icon: <UserAddOutlined />,
                        label: "Invite Registered User",
                        onClick: showRegisterUserModal,
                      },
                    ],
                  }}
                  placement="bottom"
                  arrow
                  trigger={["click"]}
                >
                  <Button
                    icon={<UserAddOutlined />}
                    onClick={(e) => e.preventDefault()}
                  >
                    Invite
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          }
          style={{ marginBottom: "16px" }}
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey="id"
            scroll={{ x: "max-content" }}
          />
        </Card>
      </Space>

      <Modal
        title="Create User"
        open={isCreateUserModalVisible}
        onCancel={handleCreateUserCancel}
        footer={null}
      >
        <Form form={createUserForm} onFinish={handleCreateUser}>
          <Form.Item
            name="name"
            label="User Name"
            rules={[
              { required: true, message: "Please enter the user's name!" },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              autoComplete="off"
              placeholder="Enter your name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="User Email"
            rules={[
              { required: true, message: "Please enter the user's email!" },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              autoComplete="off"
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreate}
              disabled={isCreate}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Invite Registered User"
        open={isRegisterUserModalVisible}
        onCancel={handleRegisterUserCancel}
        footer={null}
      >
        <Form form={registerUserForm} onFinish={handleRegisterUser}>
          <Form.Item
            name="email"
            label="User Email"
            rules={[
              { required: true, message: "Please enter the user's email!" },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              autoComplete="off"
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreate}
              disabled={isCreate}
            >
              Invite
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <style jsx>{`
        @media (min-width: 768px) {
          .button-col {
            justify-content: flex-end;
          }
        }
      `}</style>
    </div>
  );
};

export default UserManagement;
