import React, { useState } from "react";
import axios from "axios";
import { Typography, Form, Input, Button, message, Tooltip } from "antd";
import { LockOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "./PasswordChange.css";

const { Title } = Typography;

const PasswordChange = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  async function getUserInfo() {
    try {
      const currentUserData = await fetchCurrentUser();
      const userId = currentUserData.id || currentUserData.userId;
      const userEmail = currentUserData.email || currentUserData.userEmail;
      return { userId, userEmail };
    } catch (error) {
      console.error("An error occurred while retrieving the user data:", error);
      return null;
    }
  }

  const handleSave = async (values) => {
    setLoading(true);
    const { userId } = await getUserInfo();
    try {
      await axios.post(
        `${API_URL}/api/users/change-password`,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          userId,
        },
        {
          withCredentials: true,
        }
      );
      message.success("Password changed successfully");
    } catch (error) {
      console.error("Error changing password:", error);
      message.error("Failed to change password");
    } finally {
      setLoading(false);
    }
  };

  const text = (
    <span>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, impedit
      ducimus aperiam qui tenetur culpa voluptate nobis ipsa. Fugiat obcaecati
      et odit facilis. Nam aliquam veritatis, perspiciatis quidem tenetur dolor.
    </span>
  );

  return (
    <div style={{ padding: "12px 0px" }}>
      <Title level={2}>
        Change Password{" "}
        <Tooltip placement="top" title={text}>
          <QuestionCircleOutlined style={{ fontSize: "16px" }} />
        </Tooltip>
      </Title>
      <Form form={form} onFinish={handleSave} layout="vertical">
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            className="changeInput"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input the new password!",
            },
            {
              min: 9,
              message: "Password must be at least 9 characters!",
            },
            {
              pattern: /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,])/,
              message:
                "Password must include at least one uppercase letter, one number, and one special character!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            className="changeInput"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            className="changeInput"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordChange;
