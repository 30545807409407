/* ResetPassword.jsx */

import React, { useState } from "react";
import { Form, Input, Button, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import "./AuthForm.css";

const { Title } = Typography;

const ResetPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onFinish = async (values) => {
    const { newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/api/auth/reset-password`, {
        token,
        newPassword,
      });

      if (response.status === 200) {
        message.success("Your password has been updated successfully!");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      message.error(
        "An error occurred while updating the password. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return (
      <div className="container">
        <div className="verification-form">
          <div className="logo">
            <img src="/logo2.png" alt="Logo" width={120} />
          </div>
          <Title level={3} className="title">
            Invalid token
          </Title>
          <p>It looks like the reset token is missing or invalid.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="verification-form">
        <div className="logo">
          <img src="/logo2.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="form-title" style={{ marginTop: 0 }}>
          Reset Your Password
        </Title>

        <Form
          name="reset-password"
          onFinish={onFinish}
          layout="vertical"
          className="reset-password-form"
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter a new password!",
              },
              {
                min: 9,
                message: "Password must be at least 9 characters!",
              },
              {
                pattern: /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,])/,
                message:
                  "Password must include at least one uppercase letter, one number, and one special character!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter your new password"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm your new password"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              className="button-verify"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
