import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const CreateFolderModal = ({
  isFolderModalVisible,
  setIsFolderModalVisible,
  appId,
  parentId,
  onFolderCreated,
  fetchFolders,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [folderForm] = Form.useForm();
  const [isCreate, setIsCreate] = useState(false);

  const handleCreateFolder = async (values) => {
    setIsCreate(true);
    try {
      await axios.post(
        `${API_URL}/api/folders/create-folder`,
        { ...values, app_id: appId, parent_id: parentId },
        {
          withCredentials: true,
        }
      );
      fetchFolders();
      folderForm.resetFields();
      setIsFolderModalVisible(false);
      message.success("Folder created successfully");

      if (onFolderCreated) {
        onFolderCreated();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      message.error("Failed to create folder");
    } finally {
      setIsCreate(false);
    }
  };

  return (
    <Modal
      title="Create New Folder"
      open={isFolderModalVisible}
      onCancel={() => {
        folderForm.resetFields();
        setIsFolderModalVisible(false);
      }}
      footer={null}
    >
      <Form form={folderForm} onFinish={handleCreateFolder}>
        <Form.Item
          name="name"
          label="Folder Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the folder!",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreate}
            disabled={isCreate}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFolderModal;
