import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const EditFolderModal = ({
  isFolderEditModalVisible,
  setIsFolderEditModalVisible,
  editingFolder,
  onFolderUpdated,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [folderEditForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (editingFolder) {
      folderEditForm.setFieldsValue({
        name: editingFolder.name,
      });
    }
  }, [editingFolder, folderEditForm]);

  const handleUpdateFolder = async (values) => {
    setIsEdit(true);
    try {
      await axios.put(
        `${API_URL}/api/folders/update-folder`,
        { ...values, id: editingFolder.id },
        {
          withCredentials: true,
        }
      );
      setIsFolderEditModalVisible(false);
      onFolderUpdated && onFolderUpdated();
      message.success("Folder updated successfully");
    } catch (error) {
      console.error("Error updating folder:", error);
      message.error("Failed to update folder");
    } finally {
      setIsEdit(false);
    }
  };

  return (
    <Modal
      title="Edit Folder"
      open={isFolderEditModalVisible}
      onCancel={() => setIsFolderEditModalVisible(false)}
      footer={null}
    >
      <Form form={folderEditForm} onFinish={handleUpdateFolder}>
        <Form.Item
          name="name"
          label="Folder Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the folder!",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isEdit}
            disabled={isEdit}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFolderModal;
