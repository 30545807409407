/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Button, message } from "antd";
import axios from "axios";

const { TextArea } = Input;

const SecureNoteUpdate = ({
  isModalVisible,
  setIsModalVisible,
  editNote,
  onSuccess,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isNote, setIsNote] = useState(false);

  useEffect(() => {
    if (editNote) {
      form.setFieldsValue({
        title: editNote.name,
        note: editNote.note,
      });
    } else {
      form.resetFields();
    }
  }, [editNote, form]);

  const handleUpdate = async (values) => {
    setLoading(true);
    setIsNote(true);
    try {
      await axios.put(
        `${API_URL}/api/secure-note/update-secure-note`,
        {
          title: values.title,
          note: values.note,
          noteId: editNote.key,
        },
        {
          withCredentials: true,
        }
      );

      message.success("Note updated successfully");
      setIsModalVisible(false);

      const updatedNote = {
        key: editNote.noteId,
        name: values.title,
        note: values.note,
        lastUpdate: new Date().toLocaleString(),
      };

      if (onSuccess) {
        onSuccess(updatedNote);
      }
    } catch (error) {
      console.error("Error during update:", error);

      if (error.response) {
        console.error("API error response:", error.response.data);
      }

      message.error("Failed to update the note.");
    } finally {
      setLoading(false);
      setIsNote(false);
    }
  };

  return (
    <Modal
      title="Update Secure Note"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={isNote}
          onClick={() => form.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleUpdate} layout="vertical">
        <Form.Item
          label="Note Title"
          name="title"
          rules={[{ required: true, message: "Please enter title!" }]}
        >
          <Input placeholder="Enter note title" autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Note Content"
          name="note"
          rules={[{ required: true, message: "Please enter note content!" }]}
        >
          <TextArea rows={4} placeholder="Enter note content" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SecureNoteUpdate;
