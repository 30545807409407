import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Form, Input, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import "./AuthForm.css";

const { Title } = Typography;

function Login({ onLoginSuccess }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [loginEmail, setLoginEmail] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const savedLoginEmail = localStorage.getItem("userEmail");
    if (savedLoginEmail) {
      setLoginEmail(savedLoginEmail);
      form.setFieldsValue({ email: savedLoginEmail });
    }
  }, [form]);

  const onFinish = async (values) => {
    setIsLogin(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/login`,
        {
          email: loginEmail,
          password: values.password,
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        message.success("Login successful!");
        localStorage.setItem("userEmail", loginEmail);
        if (onLoginSuccess) onLoginSuccess();
        navigate("/dashboard");
      } else {
        message.error("Login failed. Please try again.");
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "An error occurred. Please try again.";
      message.error(errorMsg);
    } finally {
      setIsLogin(false);
    }
  };

  return (
    <div className="auth-container">
      <Form
        form={form}
        name="loginForm"
        layout="vertical"
        className="auth-form"
        onFinish={onFinish}
      >
        <div className="logo-container">
          <img src="/logo2.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="form-title">
          Login
        </Title>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Enter your email"
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Enter your password"
            autoComplete="current-password"
          />
        </Form.Item>

        <div className="forgot">
          <Link to="/forgot-password">Forgot your password?</Link>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="submit-btn"
            disabled={isLogin}
            loading={isLogin}
          >
            Login Now
          </Button>
        </Form.Item>

        <Divider plain className="divider">
          OR
        </Divider>

        <Form.Item>
          <Button
            type="default"
            block
            className="toggle-btn"
            onClick={() => navigate("/register")}
          >
            Switch to Signup
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
