/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Space,
  Typography,
  Button,
  Dropdown,
  message,
  Table,
  Input,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
  KeyOutlined,
  EyeOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  MoreOutlined,
  DownOutlined,
  ShareAltOutlined,
  ExportOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import CreateSecretModal from "./component/CreateSecretModal";
import CreateFolderModal from "./component/CreateFolderModal";
import EditSecretModal from "./component/EditSecretModal";
import EditFolderModal from "./component/EditFolderModal";
import ShareModal from "../../components/share/ShareModal";

const { Title, Text } = Typography;

const PasswordManager = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [appName, setAppName] = useState("");
  const [userId, setUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isFolderEditModalVisible, setIsFolderEditModalVisible] =
    useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [editingSecret, setEditingSecret] = useState(null);
  const [editingFolder, setEditingFolder] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [folders, setFolders] = useState([]);
  const [secrets, setSecrets] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType] = useState("checkbox");
  const [showSecretValues, setShowSecretValues] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const { appId } = useParams();
  const navigate = useNavigate();

  const showCreateSecretModal = () => setIsModalVisible(true);
  const showCreateFolderModal = () => setIsFolderModalVisible(true);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Current user information could not be retrieved:", error);
      return null;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      fetchAppDetails();
      fetchFolders();
      fetchSecrets();

      const currentUserData = await fetchCurrentUser();
      if (!currentUserData) {
        return;
      }
      const userIdFromToken = currentUserData.id || currentUserData.userId;
      setUserId(userIdFromToken);
    };

    initialize();
  }, [appId]);

  useEffect(() => {
    setFilteredData([...secrets, ...folders]);
  }, [secrets, folders]);

  const fetchAppDetails = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/apps/get-app-by-id`,
        { appId },
        {
          withCredentials: true,
        }
      );
      setAppName(response.data.name);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/folders/get-folders-by-app-id`,
        { appId },
        {
          withCredentials: true,
        }
      );
      setFolders(
        response.data.folders.map((folder) => ({
          ...folder,
          type: "Folder",
          shared: [],
        }))
      );
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const fetchSecrets = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/secrets/get-secrets-by-app-id`,
        { appId },
        {
          withCredentials: true,
        }
      );
      setSecrets(
        response.data.secrets.map((secret) => ({
          ...secret,
          type: "Secret",
          shared: [],
        }))
      );
    } catch (error) {
      console.error("Error fetching secrets:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredApps = [...secrets, ...folders].filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredApps);
  };

  const toggleSecretVisibility = (id) => {
    setShowSecretValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/folders/delete-folder`,
        { id: folderId },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        fetchFolders();
        message.success("Folder deleted successfully");
      } else {
        message.error(response.data.error || "Failed to delete folder");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      message.error(error.response?.data?.error || "Failed to delete folder");
    }
  };

  const handleDeleteSecret = async (secretId, secretName) => {
    Modal.confirm({
      title: "Are you sure you want to delete this secret?",
      content: (
        <span>
          You are about to delete the secret: <Text strong>{secretName}</Text>.
          This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const url = `${API_URL}/api/secrets/delete-secret-by-id`;
        try {
          const response = await axios.post(
            url,
            { id: secretId },
            {
              withCredentials: true,
            }
          );

          if (response.status === 200) {
            message.success(`Secret "${secretName}" deleted successfully.`);
            fetchSecrets();
          } else {
            message.error(response.data.error || "Failed to delete secret");
          }
        } catch (error) {
          console.error("Error deleting secret:", error);
          message.error(
            error.response?.data?.error || "Failed to delete secret"
          );
        }
      },
    });
  };

  const handleDeleteSelected = () => {
    const selectedItems = selectedRowKeys
      .map((key) => filteredData.find((item) => item.id === key))
      .filter(Boolean);

    const itemNames = selectedItems.map((item) => item.name).join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected items?",
      content: (
        <span>
          You are about to delete the following items:{" "}
          <Text strong>{itemNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          for (const item of selectedItems) {
            if (item.type === "Folder") {
              await axios.post(
                `${API_URL}/api/folders/delete-folder`,
                { id: item.id },
                { withCredentials: true }
              );
            } else if (item.type === "Secret") {
              await axios.post(
                `${API_URL}/api/secrets/delete-secret-by-id`,
                { id: item.secret_id },
                { withCredentials: true }
              );
            }
          }
          fetchFolders();
          fetchSecrets();
          setSelectedRowKeys([]);
          message.success("Selected items deleted successfully.");
        } catch (error) {
          console.error("Error deleting selected items:", error);
          message.error("Failed to delete selected items.");
        }
      },
    });
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((item) => ({
      Name: item.name,
      Type: item.type,
      Value: item.type === "Secret" ? item.value : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Password Manager");

    const fileName = `${appName.replace(/\s+/g, "-")}_data.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const handleShareClick = (record) => {
    setSelectedRecords([record]);
    setIsShareModalVisible(true);
  };

  const handleShareSelected = () => {
    const selectedItems = selectedRowKeys.map((key) =>
      filteredData.find((item) => item.id === key)
    );
    setSelectedRecords(selectedItems);
    setIsShareModalVisible(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <Link to={`/apps/${appId}/${record.id}`}>
              <FolderOutlined /> <strong>{text}</strong>
            </Link>
          ) : (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          )}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 8 }}>
              {showSecretValues[record.id] ? text : "••••••••"}
            </span>
            <Space>
              <Button
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                type="text"
                onClick={() => navigator.clipboard.writeText(text)}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
    {
      key: "actions",
      render: (_, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  icon: <EditOutlined />,
                  label: "Edit",
                  onClick: () =>
                    record.type === "Folder"
                      ? (setEditingFolder(record),
                        setIsFolderEditModalVisible(true))
                      : (setEditingSecret(record), setIsEditModalVisible(true)),
                },
                {
                  key: "2",
                  icon: <ShareAltOutlined />,
                  label: "Share",
                  onClick: () => handleShareClick(record),
                },
                {
                  key: "3",
                  icon: (
                    <DeleteOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  ),
                  label: (
                    <Space
                      style={{
                        color: "red",
                      }}
                    >
                      Delete
                    </Space>
                  ),
                  onClick: () =>
                    record.type === "Folder"
                      ? handleDeleteFolder(record.id)
                      : handleDeleteSecret(record.secret_id, record.name),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const text = (
    <span>
      You can create and share secrets and folders within your apps. 
    </span>
  );

  return (
    <div style={{ padding: "12px 0" }}>
      <Space align="center">
        <Button
          type="text"
          icon={<ArrowLeftOutlined style={{ fontSize: "18px" }} />}
          onClick={() => navigate(-1)}
          style={{ marginBottom: "15px" }}
        />
        <Title level={2}>
          {appName}{" "}
          <Tooltip placement="top" title={text}>
            <QuestionCircleOutlined style={{ fontSize: "16px" }} />
          </Tooltip>
        </Title>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}></Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      icon: <PlusOutlined />,
                      label: "Create Secret",
                      onClick: showCreateSecretModal,
                    },
                    {
                      key: "2",
                      icon: <PlusOutlined />,
                      label: "Create Folder",
                      onClick: showCreateFolderModal,
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button type="primary">
                  <PlusOutlined /> Create
                </Button>
              </Dropdown>

              <Button
                onClick={handleShareSelected}
                disabled={selectedRowKeys.length === 0}
                icon={<ShareAltOutlined />}
              >
                Share
              </Button>

              <Dropdown
                disabled={selectedRowKeys.length === 0}
                menu={{
                  items: [
                    {
                      key: "1",
                      icon: <DeleteOutlined style={{ color: "red" }} />,
                      label: <Space style={{ color: "red" }}>Delete</Space>,
                      onClick: handleDeleteSelected,
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 250 }}
                  autoComplete="off"
                />
              </Col>
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        icon: <ExportOutlined />,
                        label: "Export",
                        onClick: handleExport,
                      },
                    ],
                  }}
                  placement="bottom"
                  arrow
                  trigger={["click"]}
                >
                  <Button type="text">
                    <MoreOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey={(record) => record.id}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ x: "max-content" }}
        />
      </Space>

      <CreateSecretModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        fetchSecrets={fetchSecrets}
        appId={appId}
      />

      <CreateFolderModal
        isFolderModalVisible={isFolderModalVisible}
        setIsFolderModalVisible={setIsFolderModalVisible}
        fetchFolders={fetchFolders}
        appId={appId}
      />

      <EditSecretModal
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        editingSecret={editingSecret}
        onSecretUpdated={() => fetchSecrets()}
      />

      <EditFolderModal
        isFolderEditModalVisible={isFolderEditModalVisible}
        setIsFolderEditModalVisible={setIsFolderEditModalVisible}
        editingFolder={editingFolder}
        onFolderUpdated={() => fetchFolders()}
      />

      <ShareModal
        visible={isShareModalVisible}
        onCancel={() => setIsShareModalVisible(false)}
        items={selectedRecords.map((rec) => ({
          id: rec.id,
          type: rec.type.toLowerCase(),
          appId: appId,
        }))}
        userId={userId}
      />
    </div>
  );
};

export default PasswordManager;
