import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result, Space } from "antd";

const NotFound = () => {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  return (
    <Result
      status="404"
      title="Page Not Found"
      subTitle="The requested page was not found, it may have been deleted or moved elsewhere"
      extra={
        <Space>
          <Button type="primary" onClick={() => navigate("/dashboard")}>
            Go to Dashboard
          </Button>
          <Button>
            <a
              href={`mailto:securenook@gmail.com?subject=Feedback on 404 Page&body=I encountered a 404 error at this URL: ${currentURL}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Feedback
            </a>
          </Button>
        </Space>
      }
    />
  );
};

export default NotFound;
